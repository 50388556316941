.home {
	h1 {
		font-weight: 300;
	}
}

.list {
	.checkbox {
		min-width: 100%;

		input[type="checkbox"] {
			position: absolute;
			left: -30px;
			display: none;
		}

	  label {
		position: relative;
		display: flex;
		flex-flow: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin: 0;
		height: 20px;
		margin-bottom: 20px;
		padding-left: 40px;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 20px;
			height: 20px;
			border: #dedede solid 1px;
			cursor: pointer;
		}

		&:after {
			content: "";
			cursor: pointer;
		}
	  }

	  input[type="checkbox"]:checked + label {

		&:after {
			content: "";
			position: absolute;
			left: 6px;
			top: 2px;
			width: 16px;
			height: 8px;
			border-left: $yellow solid 5px;
			border-bottom: $yellow solid 5px;
			transform: rotate(-45deg);
		}
	  }
	}
}