// ------------------------------------------------
// Site Name: 
// Author: 
// ------------------------------------------------

* {
	box-sizing: border-box;
}

body, html {
  margin: 0;
}

body {
  font-family: $main;
  color: $darktext;
  background-color: $bg;
}

img {
  display: block;
  border: none;
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  
  li {
    list-style: none;
  }
}

a {
  display: inline-block;
}

.heatmap {
  position: relative;
  padding-top: 0;

  #map {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.confirm-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;

  .confirm-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $bg;
    padding: 40px 30px;

    p {
      text-align: center;
    }

    .btn {
      margin: 0 10px;
    }
  }
}

.dropzone-holder {
  position: relative;

  .images-list {

    li {
      display: inline-block;
      margin: 5px 0;
      padding: 10px;

      &:hover {
        background-color: $bg;
      }

      .del {
        display: inline-block;
        color: #b53030;
        cursor: pointer;
      }
    }

    img {
      height: 120px;
    }
  }
}

.page-holder {
  position: absolute;
  width: 100%;
  right: 0;

  &.open {
    @include XGA {
      width: calc( 100% - 260px );
    }

    @include HD {
      width: calc( 100% - 300px );
    }

    @include UXGA {
      width: calc( 100% - 360px );
    }
  }

  @include XGA {
    width: calc( 100% - 80px );
    transition: width .3s $QuadEaseInOut;
  }

  @include UXGA {
    width: calc( 100% - 110px );
  }
}

h1, h2, h3, h4, h5, h6 {
  color: #20315b;
}

section {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  padding-top: 90px;

  @include XGA {
    padding-top: 140px;
  }
}

.container {
  position: relative;
  width: calc( 100% - 40px );
  margin: 0 auto;

  @include XGA {
    width: calc( 100% - 64px; );
  }
}

.btn-holder {
  text-align: center;
}

.btn {
  position: relative;
  background-color: #fff;
  border: $yellow solid 1px;
  padding: 10px 25px;
  margin-top: 20px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  transition: background-color .2s $CubicEaseInOut;

  &.btn-logout {
    background-color: $yellow;

    &:hover {
      span {
        color: #fff;
      }
    }

    span {
      text-transform: none;
      color: #20315b;
      text-decoration: none;
    }

    &:before {
      background-color: #fff;
    }
  }

  @include HD {
    padding: 12px 35px;
  }

  span {
    position: relative;
    color: #fff;
    transition: color .2s $CubicEaseInOut;
    font-weight: 700;
    text-transform: uppercase;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $yellow;
    transition: opacity .2s $CubicEaseInOut;
  }

  &:hover {
    span {
      color: #20315b;
    }

    &:before {
      opacity: 0;
    }
  }

  &:active {
    transform: scale(0.96);
  }
}

.parsley-errors-list {
  display: none;
}

.parsley-error {
  border-color: #f00 !important;
}

.list-items {
  position: relative;
  margin: 0;
  min-height: 90px;

  h5 {
    padding: 40px 20px;
  }

  p {
    overflow-wrap: break-word;
    max-width: 100%;
  }

  li {
    position: relative;
    background-color: #fff;
    padding: 40px 30px;

    &.highlighted {
      outline: 4px solid yellow;
      outline-offset: -4px;

      &::after {
        display: none;
      }
    }

    .num {
      position: absolute;
      left:8px;
      top: 8px;
    }

    &:hover {
      background-color: #f8f8f8;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 30px;
      width: calc( 100% - 60px );
      height: 1px;
      background-color: #dcdcdc;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    .thumb {
      position: relative;
      left: 0;
      top: 0;
      height: 100px;

      @include XGA {
        position: absolute;
        height: 100%;
        width: 170px;
      }

      img {
        position: relative;
        max-height: 100%;
        max-width: 150px;

        @include XGA {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    h4 {
      &.withimg {
        @include XGA {
          margin-left: 160px;
        }
      }
    }

    .controls {
      position: absolute;
      right: 30px;
      top: 35px;

      div {
        display: flex;
        padding: 5px 0;

        span {
          cursor: pointer;
          font-size: 16px;
        }

        img {
          cursor: pointer;
          margin-right: 5px;
        }

        a {
          display: flex;
          color: #000;
          text-decoration: none;
          font-size: 16px;
        }
      }
    }
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100% !important;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.93);
  overflow-x: hidden;

  span {
    position: absolute;
    left: 0%;
    top: 2px;
    width: 50px;
    height: 6px;
    background-color: $yellow;
    animation: move 1.2s $QuadEaseInOut infinite;
  }
}

.dropzone {
  width: 100%;
  height: auto;
  min-height: 240px;
  border-radius: 5px;
  border: #bebebe dashed 3px;

  &.dropzone-active {
    border: #a0f7a0 solid 3px;
    background-color: #f5f7f8;
  }

  &.dropzone-reject {
    border: $yellow solid 3px;
    background-color: #f5f7f8;
  }

  .contest-image {
    width: 100%;
    padding: 10px;

    img {
      max-width: 100%;
      max-height: 240px;
      margin: 0 auto;
    }

    &.image-preview {
      img {
        display: inline-block;
        max-height: 120px;
        margin: 5px;
      }
    }
  }

  .text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    color: #bebebe;
    font-weight: 500;
    text-align: center;

    .small {
      font-size: 10px;
    }
  }
}

.notifications {
  position: fixed;
  top: 90px;
  right: 10px;
  z-index: 30000;

  @include XGA {
    top: 120px;
    right: 15px;
  }

  .notification {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 30px;
    font-size: 13px;
    padding: 15px 30px;
    padding-right: 50px;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.4s $QuadEaseInOut;

    &:before {
      content: "";
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 3px;
      height: 100%;
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: #fff;
    }

    &.red {
      &:before {
        background-color: #b53030;
      }
    }

    &.green {
      &:before {
        background-color: #0e9200;
      }
    }

    .close {
      position: absolute;
      right: 0;
      top: 3px;
    }

    p {
      margin: 0;
    }
  }
}

.search-box {
  margin-top: 20px;

  @include vHD {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }
}

.search-input {
  position: relative;
  width: 200px;
  outline: none;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 20px 0;
  text-align: center;
  justify-content: center;

  li {
    padding: 5px;
    color: $yellow;
    cursor: pointer;

    &.active {
      font-weight: 700;
    }

    &.disabled {
      display: none;
    }
  }
}

.search-input::before {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==');
  display: block;
  position: absolute;
  width: 15px;
  z-index: 3;
  height: 15px;
  font-size: 20px;
  top: 0;
  left: 12px;
  line-height: 32px;
  opacity: 0.6;
}

.si-select::before {
  display: none;
}

.search-input > input, .search-input > select {
  width: 100%;
  font-size: 14px;
  border: #dcdcdc solid 1px;
  line-height: 22px;
  padding: 5px 5px 5px 35px;
  height: 32px;
  position: relative;
  outline: none;
  color: #000;
}

.si-select select {
  padding-left: 5px;
}

.search-input > input:focus {
  outline: none;
}

.if-component {
  .inputs {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
  }

  ul {
    li {
      position: relative;
      padding-right: 20px;

      .remove {
        position: absolute;
        right: 0;
        top: 4px;
        width: 12px;
        height: 12px;
        cursor: pointer;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          width: 100%;
          height: 2px;
          background-color: #000;
          transform: rotate(-45deg);
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          width: 100%;
          height: 2px;
          background-color: #000;
          transform: rotate(45deg);
        }
      }
    }
  }
}

.remove-file {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #f44;
  cursor: pointer;
}

::-webkit-scrollbar {
  @include HD {
	 width: 10px;
  }
}

::-webkit-scrollbar-button {
  @include HD {
    display: none;
  }
}

::-webkit-scrollbar-track {
  @include HD {
    position: relative;
    background-color: #cfcfcf;
    border-radius: 5px;
  }
}

::-webkit-scrollbar-track-piece {
  @include HD {
    background-color: #cfcfcf;
  }
}

::-webkit-scrollbar-thumb {
  @include HD {
    background-color: $yellow;
  }
}

::-webkit-scrollbar-corner {

}

::-webkit-resizer {

}