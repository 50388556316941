.login {
	position: absolute;
	height: 100vh;
	min-height: 600px;
	background-size: cover;
	background-position: left bottom;
	background-image: url("../../img/2018_bg_2.jpg");
	padding: 10px 0;

	@include XGA {
		position: fixed;
	}

	.login-logo {
		display: inline-block;
		width: 120px;
	}

	.login-box {
		width: 90%;
		max-width: 340px;
		margin: 80px auto;
		background-color: rgba(255, 255, 255, 0.7);
		padding: 20px 30px;
		transform: translateX(30px);
		transition: transform .6s $QuadEaseOut;
		text-align: center;

		&.show {
			transform: translateX(0);

			@include XGA {
				transform: translateX(0) translateY(-50%);
			}
		}

		@include vHD {
			max-width: 380px;
		}

		@include XGA {
			position: absolute;
			top: 50%;
			right: 15%;
			transform: translateY(-50%) translateX(30px);
			margin: 0;
		}

		@include HD {
			max-width: 400px;
		}

		@include UXGA {
			max-width: 480px;
		}

		.top {
			display: flex;
			flex-flow: row;
			flex-wrap: nowrap;

			span {
				letter-spacing: 10px;
				line-height: 55px;
				margin-right: 10px;

				@include XGA {
					font-size: 34px;
					line-height: 65px;
				}

				@include UXGA {
					line-height: 90px;
				}
			}
		}

		h3 {
			font-weight: 400;
			text-align: center;

			@include UXGA {
				font-size: 25px;
			}
		}

		.inputs {
			margin: 30px 0;

			input {
				width: 100%;
				font-size: 16px;
				padding: 20px 35px;
				background-color: rgba(255, 255, 255, 0.7);
				border: #d0d0d0 solid 1px;
				outline: none;
				margin-bottom: 10px;

				&::placeholder {
					color: #747474;
				}
			}

			.error {
				color: #d00;
				font-size: 11px;
				margin: 10px;
				margin-top: -5px;
			}
		}
	}
}