section.complaint {
	p.error {
		color: red;
		margin: 0px 0 4px;
	}
	
	button {
		margin-top: auto;
		padding: 6px 24px;
		border-color: #027;
		color: #027;
		min-width: 200px;
		cursor: pointer;

		&:disabled {
			opacity: 0.8;
			border-color: gray;
			color: gray;
			pointer-events: none;
			cursor: default;
		}
	}

	// .pagination {
	// 	justify-content: space-between;
	// 	font-size: 18px;
	// 	line-height: 133%;
	// 	margin-bottom: 24px;
	// }

	.checkbox {
		margin-top: 12px;
	}

	.search-input {
		min-width: 250px;
	}

	.search-input.disable-before {
		&::before {
			display: none;
		}
	}

	.upper-settings {
		font-size: 18px;
		line-height: 133%;
		margin-bottom: 24px;
		
		@include XGA {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

		.pagination {
			grid-column: 1/3;
			margin-bottom: 0;
		}

		.search-box-complaint {
			display: block;
			grid-row: 1/3;

			&>* {
				display: block
			}

			input {
				min-width: 300px;
			}

			button {
				margin-top: 16px;
			}
		}

		.elements-on-page {
			margin-left: auto;
			
			select {
				padding: 5px;
				width: fit-content;
			}
		}
	}

	.complaint-wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding-bottom: 24px;

		.complaint-element {
			border: 1px solid #027;
			padding: 24px;

			&>* {
				font-size: 18px;
				line-height: 133%;
			}

			.inner,
			.change-status {
				display: grid;
				gap: 12px;
				grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
			}

			.change-status {
				margin-top: 24px;

				&>div {
					width: fit-content;

					label {
						margin-bottom: 8px;
					}
				}

				.select-wrapper {
					display: flex;
					flex-direction: column;
					height: 100%;

					select {
						font-size: 18px;
						line-height: 133%;
						height: 37px;
					}

					button {
						margin-top: 12px;
					}
				}

				.textarea-wrapper {
					p.error {
						color: red;
						margin-bottom: 2px;
						margin-top: 4px;
					}
					
					textarea {
						resize: none;
						border: 1px solid #027;
						width: 100%;
						min-width: 400px;
						overflow: initial;
						padding: 6px;
					}
				}
			}
		}
	}
}